<svg
	xmlns="http://www.w3.org/2000/svg"
	class="icon icon-tabler icon-tabler-circle {$$props.class}"
	viewBox="0 0 24 24"
	stroke-width="1.5"
	stroke="currentColor"
	fill={$$props.fill ?? 'none'}
	stroke-linecap="round"
	stroke-linejoin="round"
>
	<path stroke="none" d="M0 0h24v24H0z" fill="none" />
	<circle cx="12" cy="12" r="9" />
</svg>
